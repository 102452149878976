const forbiddenWords = [
  'police',
  'car',
  'auto',
  'automotive',
  'vehicle',
  'audi',
  'BMW',
  'cadillac',
  'fiat',
  'ford',
  'honda',
  'hyundai',
  'mazda',
  'nissan',
  'opel',
  'saab',
  'toyota',
  'tesla',
  'volkswagen',
  'volvo',
  'truck',
  'bus',
  'transport',
  'transportation',
  'metro',
  'train',
  'trains',
  'classictrains',
  'caltrain',
  'localtrain',
  'trein',
  'keteltrein',
  'rail',
  'railroad',
  'railway',
  'railways',
  'railogix',
  'railrunner',
  'captrain',
  'CSXT',
  'HSL',
  'GNWR',
  'goederen',
  'locomotive',
  'plane',
  'air',
  'airplane',
  'airline',
  'airways',
  'aircraft',
  'airport',
  'vessel',
  'ship',
  'fleet',
  'freight',
  'marine',
  'pano',
  'panorama',
  'abstract',
  'minimal',
  'repetition',
  'repeat',
  'microphotography',
  'product',
  'investment',
  'affairs',
  'embassy',
  'minister',
  'candidate',
  'president',
  'meeting',
  'conference',
  'manager',
  'defense',
  'defence',
  'force',
  'department',
  'guard',
  'navy',
  'naval',
  'military',
  'fighter',
  'gun',
  'attack',
  'emergency',
  'fire',
  'burning',
  'smoke',
  'brigade',
  'map',
  'selfie',
  'selfportrait',
  'insect',
  'bird',
  'model',
  'sexy',
  'shirtless',
  'fashion',
  'archeon',
  'anime',
  'design',
  'store',
  'singer',
  'flower',
  'plant',
  'galaxy',
  'astronomy',
  'stars',
  'cat',
  'katzen',
  'dog',
  'labrador',
  'buddies',
  'christmas',
  'eneq',
  'EVS',
]

export default forbiddenWords
